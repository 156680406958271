/* --------------------
   -------------------- Reset -------------------- */
* { margin: 0; outline: none; }
body{overflow-x:hidden;}
header, nav, section, article, aside, footer { display: block; }
body, p, pre, caption, h1, h2, h3, h4, h5, h6, form, div, fieldset, legend { padding: 0; }

html { width: 100%; height: 100%; }
body { position: relative; min-height: 100%; height: 100%; width: 100%; max-width: 100%; overflow-x: hidden; font: 400 14px sans-serif; color: #202020; background: #fff; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -ms-overflow-style: scrollbar; }
img, fieldset { border: 0; }

input, textarea { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; -webkit-border-radius:0;  border-radius:0; -webkit-appearance: none;}
input::-moz-focus-inner, textarea::-moz-focus-inner, select::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }
input[type="text"], input[type="password"], button, textarea { -webkit-appearance: none; }

a{text-decoration: none !important;}
a[href^="tel:"] { text-decoration: none !important; }
a, button, span,input[type="submit"] { text-decoration: none; -webkit-transition: color 400ms, background 400ms, border 400ms, opacity 400ms; -moz-transition: color 400ms, background 400ms, border 400ms, opacity 400ms; transition: color 400ms, background 400ms, border 400ms, opacity 400ms; }
ul{list-style:none; margin:auto;}
a:focus{outline:none;}  

.skrollr-desktop body {height:100% !important; } 
.parallax-image-wrapper {position:fixed; left:0; width:100%; overflow:hidden;}
.parallax-image-wrapper-50 {height:50%; top:-50%;}
.parallax-image-wrapper-100 {height:100%; top:-100%;}
.parallax-image {display:none; position:absolute; bottom:0; left:0; width:100%; background-repeat:no-repeat; background-position:center; background-size:cover;}
.parallax-image-50 {height:200%; top:-50%;}
.parallax-image-100 {height:100%; top:0;}
.parallax-image.skrollable-between {display:block;}
.no-skrollr .parallax-image-wrapper {display:none !important;}
#skrollr-body {height:100%; overflow:visible; position:relative;}
.gap {background:transparent center no-repeat;background-size:cover;}
.skrollr .gap {background:transparent !important;}
.gap-50 {height:50%;}
.gap-100 {height:100%;}
.content-full {height:100%;}
#done {height:100%;}