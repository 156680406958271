/*------------------------------------------------------------------
[Master Stylesheet]

Project:	Yacht
Version:	1.1
Last change:	09/05/2016
Primary use:	Yacht
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]
1. EFFECTS
2. COLORS
3. GENERAL
4. HEADER
5. SECTION
6. FOOTER
7. COMMENT
8. INDEX 01
9. INDEX 02
10. INNER PAGE
11. BLOG DETAIL
12. CATEGORY
13. PRODUCT SINGLE
14. CONTACT
15. MEDIA CSS
-------------------------------------------------------------------*/
/*
EFFECTS
*/
.y-transtion, h1, h2, h3, h4, h5, h6, a, .header .fa, .y-landing .y-landing_list img, .y-header_outer nav, .y-header_outer .header, .y-header_strip .header, .y-our_services .y-servie_info, .y-boat_type .fa-anchor, .y-side_tags_list li, .y-product_listing > div .m-prod_list_text, .y-yacht_intro_img img, .y-product_filter_side, .y-product_filter_side.y-side_filter_open {
  transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out; }

.y-transtion_fast {
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; }

/*
COLORS
*/
/*
GENERAL
*/
a {
  color: #002E5B; }

body {
  font-family: "Raleway", "sans-serif";
  font-size: 16px;
  color: #464646;
  background: #FFFFFF; }

h1 {
  text-transform: uppercase;
  font-size: 36px; }

h2 {
  font-size: 32px; }

h3 {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 300;
  padding-bottom: 10px;
  text-transform: uppercase;
  position: relative; }

h3::before {
  background: #cccccc none repeat scroll 0 0;
  border-radius: 50%;
  bottom: -1px;
  content: "";
  display: block;
  height: 5px;
  left: 116px;
  position: absolute;
  width: 5px;
  z-index: 999; }

h3::after {
  border-top: 1px solid #cccccc;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  width: 120px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab";
  font-weight: normal;
  color: #002E5B;
  margin-top: 0;
  margin-bottom: 15px; }

a:hover {
  color: #002E5B; }

ul {
  list-style: disc; }

p {
  line-height: 22px;
  margin: 20px 0;
  font-size: 14px; }

.fa-twitter {
  color: #55acee; }

.fa-google-plus {
  color: #dc4e41; }

.fa-facebook-square {
  color: #3b5998; }

.fa-youtube-square {
  color: #cc0000; }

.fa-instagram {
  color: #4b7699; }

.y-loading {
  background: #ffffff none repeat scroll 0 0;
  height: 100%;
  max-width: 100% !important;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999; }
  .y-loading img {
    width: 64px;
    height: 64px;
    margin: auto;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute; }

.select-wrapper {
  display: inline-block;
  border: 1px solid #002E5B;
  background: url("../../images/dropdown.png") no-repeat right center;
  cursor: pointer;
  background-size: 12px;
  background-color: #002E5B; }

.select-wrapper, .select-wrapper select {
  height: 40px;
  position: relative;
  line-height: 34px;
  width: 100%; }

.select-wrapper:hover {
  background-image: url("../../images/dropdown.png") no-repeat right center; }

.select-wrapper .holder {
  color: #FFFFFF;
  display: block;
  margin: 0 35px 0 5px;
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative; }

.select-wrapper select {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0; }

.y-button:hover, .y-footer .y-special_offer ul li .y-read_more:hover, .y-home_blog_content .y-btn:hover {
  color: #ffffff;
  border-color: #002E5B; }

.y-button:hover::before, .y-footer .y-special_offer ul li .y-read_more:hover::before, .y-home_blog_content .y-btn:hover::before, .y-button:focus::before, .y-footer .y-special_offer ul li .y-read_more:focus::before, .y-home_blog_content .y-btn:focus::before, .y-button:active::before, .y-footer .y-special_offer ul li .y-read_more:active::before, .y-home_blog_content .y-btn:active::before {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1); }

.y-button::before, .y-footer .y-special_offer ul li .y-read_more::before, .y-home_blog_content .y-btn::before {
  background: #002E5B none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleY(0);
  transform-origin: 50% 50% 0;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  z-index: -1; }

.y-button, .y-footer .y-special_offer ul li .y-read_more, .y-home_blog_content .y-btn {
  backface-visibility: hidden;
  border-left: 5px solid #002E5B;
  background: #e1e1e1 none repeat scroll 0 0;
  box-shadow: 0 2px 2px #bfbfbf;
  -moz-box-shadow: 0 2px 2px #bfbfbf;
  -webkit-box-shadow: 0 2px 2px #bfbfbf;
  display: inline-block;
  position: relative;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: color;
  vertical-align: middle; }

::-webkit-input-placeholder {
  color: #FFFFFF;
  opacity: 1; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #FFFFFF;
  opacity: 1; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFFFFF;
  opacity: 1; }

:-ms-input-placeholder {
  color: #FFFFFF;
  opacity: 1; }

.video-container {
  position: relative;
  padding-bottom: 55%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.fa-times-circle {
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 38px !important;
  margin: auto;
  text-align: center;
  width: 94%; }

.header .fa {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 2px;
  text-align: center;
  width: 20px;
  background: transparent; }

.header .fa:hover {
  background: #002E5B; }

form.y-form .y-select_full > span {
  width: 100%; }
form.y-form .y-select_half > span {
  width: 49%; }
form.y-form .y-select_half input {
  width: 49.2%; }
form.y-form .y-bots_types img {
  display: inline-block;
  vertical-align: bottom; }
form.y-form button {
  margin-top: 15px;
  padding: 13px;
  background: none;
  box-shadow: none !important;
  border: 1px solid #FFFFFF;
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  width: 100%; }
form.y-form input {
  width: 100%;
  color: #FFFFFF;
  padding: 5px 10px;
  height: 40px;
  line-height: 34px;
  background: #002E5B;
  border: none;
  margin-bottom: 8px;
  font-size: 14px; }
form.y-form textarea {
  color: #FFFFFF;
  width: 100%;
  height: 100px;
  resize: none;
  padding: 10px;
  background: #002E5B;
  border: none;
  margin-bottom: 8px;
  font-size: 14px;
  font-family: "Raleway", "sans-serif"; }

.y-line {
  opacity: .4;
  background: #a0a0a0 none repeat scroll 0 0;
  right: 74px;
  position: absolute;
  top: 1px;
  width: 1px;
  z-index: 999; }

.y-inner_page {
  position: relative; }

.y-back_to_top {
  bottom: 74.5px;
  color: #5a5a5a;
  cursor: pointer;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  position: fixed;
  right: 50px;
  text-align: center;
  width: 50px;
  z-index: 99999; }

.camera_prev {
  background: #ffffff none repeat scroll 0 0;
  left: 0;
  opacity: 1 !important; }

.camera_next {
  background: #ffffff none repeat scroll 0 0;
  right: 0;
  opacity: 1 !important; }

.y-landing {
  padding-bottom: 10px; }
  .y-landing .y-single_info_inner {
    margin-top: -200px; }
  .y-landing .y-landing_but {
    position: relative;
    height: 100px; }
  .y-landing .y-logo {
    margin-top: 10px;
    display: inline-block;
    top: 125px;
    position: relative; }
  .y-landing .header {
    padding-bottom: 10px; }
  .y-landing .y-section_inner {
    padding-top: 30px; }
  .y-landing .y-section_inner h2 {
    color: #002E5B; }
  .y-landing .y-landing_list {
    margin-top: 30px; }
    .y-landing .y-landing_list > div {
      margin-bottom: 30px; }
    .y-landing .y-landing_list img {
      box-shadow: 0 0 5px #d7d7d7;
      -webkit-box-shadow: 0 0 5px #d7d7d7;
      -moz-box-shadow: 0 0 5px #d7d7d7;
      margin: 15px auto 10px;
      max-width: 500px;
      width: 100%; }
    .y-landing .y-landing_list img:hover {
      box-shadow: 0 5px 5px #949494;
      -webkit-box-shadow: 0 5px 5px #949494;
      -moz-box-shadow: 0 5px 5px #949494; }
    .y-landing .y-landing_list h3 {
      font-size: 20px;
      font-weight: 700;
      color: #002E5B;
      text-align: center;
      margin-bottom: 0;
      border: none; }
    .y-landing .y-landing_list h3:after {
      display: none; }
  .y-landing .y-button, .y-landing .y-footer .y-special_offer ul li .y-read_more, .y-footer .y-special_offer ul li .y-landing .y-read_more, .y-landing .y-home_blog_content .y-btn, .y-home_blog_content .y-landing .y-btn {
    background: #3aa6f5 none repeat scroll 0 0;
    bottom: 0;
    color: #ffffff;
    display: block;
    font-size: 22px;
    font-weight: 600;
    height: 50px;
    left: 0;
    letter-spacing: 0;
    line-height: 45px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 250px; }

@media (max-width: 1023px) {
  .y-landing .y-logo img {
    max-height: 70px; }

  .y-landing .y-logo {
    top: 25px; } }
/* HEADER */
.y-header_outer nav {
  border-top: 1px solid #dbdaeb;
  margin-top: 8px;
  text-align: center; }
  .y-header_outer nav ul {
    padding-left: 0; }
    .y-header_outer nav ul li {
      text-align: left; }
.y-header_outer address {
  padding-top: 10px; }
  .y-header_outer address a {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto Slab";
    font-size: 12px; }
  .y-header_outer address a:hover {
    color: #3aa6f5; }
  .y-header_outer address i {
    margin-right: 5px;
    font-size: 18px;
    position: relative;
    top: 6px;
    color: #002E5B; }
.y-header_outer .y-social {
  padding-top: 0;
  position: absolute;
  right: 15px;
  top: 22px; }
.y-header_outer .y-logo {
  margin-top: 10px;
  display: inline-block; }
.y-header_outer .header:hover {
  background: #FFFFFF; }
.y-header_outer .header {
  width: 100%;
  background: rgba(255, 255, 255, 0.8); }

.y-form_gap {
  padding-right: 35px; }

.y-header_strip.y-header_outer > div#y-header_form {
  position: static;
  margin-top: -165px; }

.y-header_strip .header {
  position: static;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8); }
.y-header_strip .y-logo {
  margin-top: 10px;
  display: inline-block; }
.y-header_strip #y-header_form {
  bottom: 0;
  position: absolute;
  margin-top: auto;
  width: 100%; }
.y-header_strip .y-header_form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px; }
  .y-header_strip .y-header_form .y-form_gap button {
    width: 100%; }
  .y-header_strip .y-header_form .y-form_gap i {
    position: relative !important;
    top: 5px;
    color: #FFFFFF !important;
    margin-right: 5px; }
  .y-header_strip .y-header_form h3 {
    color: #002E5B;
    font-size: 18px;
    padding-bottom: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase; }
  .y-header_strip .y-header_form .select-wrapper .holder {
    margin-top: 3px;
    margin-left: 8px; }
  .y-header_strip .y-header_form .y-button, .y-header_strip .y-header_form .y-footer .y-special_offer ul li .y-read_more, .y-footer .y-special_offer ul li .y-header_strip .y-header_form .y-read_more, .y-header_strip .y-header_form .y-home_blog_content .y-btn, .y-home_blog_content .y-header_strip .y-header_form .y-btn {
    width: auto;
    display: inline-block;
    background: #3AA6F5;
    border-color: transparent; }
  .y-header_strip .y-header_form p {
    margin: auto auto 15px;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    text-transform: capitalize; }
    .y-header_strip .y-header_form p a {
      color: #007ac2;
      font-weight: 600;
      font-style: italic;
      margin-left: 5px; }
  .y-header_strip .y-header_form .y-form > div.col-lg-4 > div {
    position: relative;
    padding-left: 35px; }
    .y-header_strip .y-header_form .y-form > div.col-lg-4 > div span, .y-header_strip .y-header_form .y-form > div.col-lg-4 > div input {
      margin-bottom: 10px; }
    .y-header_strip .y-header_form .y-form > div.col-lg-4 > div i {
      position: absolute;
      z-index: 999;
      left: 0;
      font-size: 26px;
      color: #002E5B;
      top: 7px; }

.y-header_top_info {
  padding: 3px 0 0; }

.y-lang_icons, .y-login_but {
  padding-left: 0; }
  .y-lang_icons li, .y-login_but li {
    display: inline-block; }
  .y-lang_icons img, .y-login_but img {
    width: 16px; }
  .y-lang_icons a, .y-login_but a {
    color: #002E5B;
    font-size: 12px;
    font-weight: 400;
    font-family: "Roboto Slab"; }

.y-lang_icons li {
  margin-right: 10px;
  padding-top: 5px; }

.y-login_but a:after {
  content: "|";
  margin: 0 10px; }
.y-login_but li:last-child a:after {
  display: none; }
.y-login_but i {
  position: relative;
  top: 5px;
  font-size: 18px; }

.y-sticky {
  background: #FFFFFF !important;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc; }
  .y-sticky address {
    margin-bottom: 5px; }
    .y-sticky address i {
      font-size: 18px; }
    .y-sticky address a {
      font-size: 14px; }
  .y-sticky .y-logo {
    margin-top: 5px; }
    .y-sticky .y-logo img {
      max-height: 60px; }
  .y-sticky nav {
    margin-top: 5px; }
  .y-sticky .flexnav li a {
    padding: 3px 0.96em; }
  .y-sticky .flexnav .touch-button {
    top: -14px; }
  .y-sticky .rmm.style {
    padding: 0; }
  .y-sticky .y-social {
    top: 8px; }
  .y-sticky .y-menu_outer {
    margin-top: 0;
    top: 10px; }

@media (min-width: 1024px) {
  .y-header_outer address {
    padding-top: 35px;
    margin: auto; } }
@media (min-width: 768px) {
  .y-header_strip nav ul, .y-header_outer nav ul {
    display: inline-block;
    width: auto;
    max-height: none; }

  .y-header_strip .header {
    position: fixed;
    z-index: 9999; } }
@media (max-width: 1200px) {
  form.y-form .y-select_33 > span {
    width: 32.3%; }

  form.y-form .y-select_33 > span select {
    width: 100%; }

  .y-logo_bg {
    border-left: 25vw solid transparent;
    border-right: 25vw solid transparent; } }
@media (max-width: 990px) {
  form.y-form .y-select_33 > span {
    width: 32%; }

  .select-wrapper .holder {
    margin-right: 0; }

  form.y-form .y-select_half > span {
    width: 48.5%; }

  form.y-form button {
    font-size: 14px;
    padding: 10px; }

  form.y-form .y-bots_types img {
    max-width: 65px; }

  .y-logo_bg {
    border-left: 30vw solid transparent;
    border-right: 30vw solid transparent;
    border-top: 20vh solid #ffffff; } }
@media (max-width: 767px) {
  .y-header_form {
    margin: auto; }

  .y-form .col-sm-5 {
    display: none; }

  form.y-form .y-select_33 > span {
    width: 32.3%; }

  form.y-form .y-select_half > span {
    width: 49.1%; }

  .y-logo_bg {
    border-left: medium none;
    border-right: medium none;
    border-top: medium none; }

  .y-logo_bg img {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }

  .y-header_form form.y-form input {
    margin-bottom: 0; }

  .y-header_strip .y-header_form .y-form > div.col-lg-3 > div {
    margin-bottom: 10px; }

  .y-header_strip .y-header_form .y-button, .y-header_strip .y-header_form .y-footer .y-special_offer ul li .y-read_more, .y-footer .y-special_offer ul li .y-header_strip .y-header_form .y-read_more, .y-header_strip .y-header_form .y-home_blog_content .y-btn, .y-home_blog_content .y-header_strip .y-header_form .y-btn {
    display: block;
    width: 100%; }

  .y-header_strip .header, .y-header_strip #y-header_form {
    position: static; }

  .parallax-image-wrapper-100 {
    display: none; } }
/* SECTION */
.y-our_services .y-service_inner {
  position: relative;
  overflow: hidden; }
.y-our_services .y-servie_info {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  position: absolute;
  top: 77%;
  width: 100%;
  height: 100%; }
  .y-our_services .y-servie_info h4 {
    margin: 0;
    font-size: 18px; }
    .y-our_services .y-servie_info h4 a {
      text-transform: uppercase;
      color: #000000;
      font-weight: 400;
      display: block;
      text-align: center;
      padding: 20px 10px; }
  .y-our_services .y-servie_info p {
    padding: 0 20px;
    text-align: center;
    color: #FFFFFF; }
.y-our_services .y-service_inner:hover .y-servie_info {
  background: #002E5B;
  top: 0; }
.y-our_services .y-service_inner:hover a {
  color: #FFFFFF; }

.y-section_inner {
  padding-top: 80px;
  padding-bottom: 30px; }
  .y-section_inner .header {
    margin-bottom: 50px; }
  .y-section_inner h2 {
    color: #002E5B;
    padding-bottom: 15px;
    text-transform: uppercase;
    margin: auto;
    position: relative; }
  .y-section_inner h2:after {
    background: #ccc none repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin: auto;
    max-width: 120px;
    position: absolute;
    right: 0;
    width: 80%; }
  .y-section_inner h2::before {
    background: #cccccc none repeat scroll 0 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    bottom: -2px;
    content: "";
    display: block;
    height: 5px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 5px; }

.y-section_content h1 {
  text-transform: uppercase;
  color: #003d70;
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative; }
.y-section_content h1::before {
  background: #cccccc none repeat scroll 0 0;
  border-radius: 50%;
  bottom: -1px;
  content: "";
  display: block;
  height: 5px;
  left: 116px;
  position: absolute;
  width: 5px;
  z-index: 999; }
.y-section_content h1::after {
  border-top: 1px solid #cccccc;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  width: 120px; }
.y-section_content h5 {
  color: #0f0d0d;
  line-height: 28px;
  margin-top: 0; }

.y-contact_side {
  background-color: #f0f0f0;
  padding-bottom: 25px;
  padding: 25px 20px; }
  .y-contact_side h3 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase; }
  .y-contact_side hr {
    margin: 0px 0 15px 0;
    border-color: #002E5B;
    border-width: 2px; }
  .y-contact_side form.y-form button {
    border: 1px solid #002E5B;
    color: #002E5B; }
  .y-contact_side form.y-form button:hover {
    color: #FFFFFF; }

.y-popular_destination .header .fa {
  background: none; }
.y-popular_destination h3 {
  font-weight: 300;
  position: relative;
  border-bottom: none; }
  .y-popular_destination h3 strong {
    text-transform: uppercase; }
.y-popular_destination .y-boat_center {
  margin: 30px auto 80px; }

.camera_prev > span:hover::before, .camera_next > span:hover::before {
  color: #002E5B; }

.y-dest_list {
  margin-top: 50px; }

.y-dest_list_single {
  margin-bottom: 70px; }
  .y-dest_list_single a {
    display: block; }
  .y-dest_list_single h2 {
    margin-bottom: 10px;
    font-size: 34px; }
  .y-dest_list_single h4 {
    margin-bottom: 0; }
  .y-dest_list_single img {
    border: 10px solid #ffffff;
    box-shadow: 0 0 3px #d0d0d0;
    -moz-box-shadow: 0 0 3px #d0d0d0;
    -webkit-box-shadow: 0 0 3px #d0d0d0; }
  .y-dest_list_single a:hover {
    color: #002E5B; }
  .y-dest_list_single h2:hover {
    border-color: #002E5B; }
  .y-dest_list_single .pull-right.col-lg-5 img {
    float: right; }
  .y-dest_list_single ul {
    margin: 20px 0;
    padding-left: 18px;
    list-style: square; }
  .y-dest_list_single li {
    line-height: 32px; }
  .y-dest_list_single .y-button, .y-dest_list_single .y-footer .y-special_offer ul li .y-read_more, .y-footer .y-special_offer ul li .y-dest_list_single .y-read_more, .y-dest_list_single .y-home_blog_content .y-btn, .y-home_blog_content .y-dest_list_single .y-btn {
    display: inline-block;
    padding: 8px 10px;
    background: #3AA6F5;
    border-color: #002E5B;
    color: #FFFFFF; }
  .y-dest_list_single .y-button:hover, .y-dest_list_single .y-footer .y-special_offer ul li .y-read_more:hover, .y-footer .y-special_offer ul li .y-dest_list_single .y-read_more:hover, .y-dest_list_single .y-home_blog_content .y-btn:hover, .y-home_blog_content .y-dest_list_single .y-btn:hover {
    color: #FFFFFF; }

.y-dest_list > div:last-child {
  margin-bottom: 0; }

.y-more_dest h3::after {
  background: #FFFFFF;
  width: 20%; }
.y-more_dest p {
  color: #FFFFFF; }
.y-more_dest img {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg); }

.y-boat_type .header .fa {
  background: #0186D5; }
.y-boat_type .y-boat_selection {
  margin-top: 50px;
  text-align: center; }
.y-boat_type .col-xs-12.y-boat_selection > div {
  display: inline-block; }
  .y-boat_type .col-xs-12.y-boat_selection > div h3 {
    font-weight: 400;
    font-size: 28px;
    color: #FFFFFF; }
.y-boat_type .y-boat_imgs img {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 15px; }
.y-boat_type .y-select_boat {
  min-width: 240px;
  margin-right: 50px; }
  .y-boat_type .y-select_boat h3 {
    border-bottom: 5px solid #FFFFFF;
    padding-bottom: 10px; }
  .y-boat_type .y-select_boat p {
    color: #FFFFFF; }
    .y-boat_type .y-select_boat p a {
      font-weight: 600;
      font-style: italic;
      color: #FFFFFF; }
.y-boat_type .y-boat_info {
  background-color: #ffffff;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%; }
  .y-boat_type .y-boat_info p {
    color: #000000;
    margin: 0; }
  .y-boat_type .y-boat_info a {
    color: #000000;
    font-size: 14px; }
  .y-boat_type .y-boat_info span {
    position: absolute;
    right: 15px;
    top: 5px;
    color: #003d70;
    font-weight: 60;
    font-style: italic;
    font-size: 24px; }
.y-boat_type .y-boat_carousel {
  clear: both;
  padding-top: 60px; }
.y-boat_type .owl-controls {
  position: relative; }
.y-boat_type .owl-controls .owl-nav:after {
  clear: both;
  display: block;
  content: ""; }
.y-boat_type .y-section_inner {
  overflow: hidden; }
.y-boat_type .owl-prev {
  float: left; }
.y-boat_type .owl-next {
  float: right; }
.y-boat_type .fa-anchor {
  font-size: 34px; }
.y-boat_type .y-right {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg); }
.y-boat_type .y-left {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg); }
.y-boat_type .fa-anchor:hover {
  color: #002E5B; }
.y-boat_type .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  position: relative;
  z-index: 99; }
.y-boat_type .owl-controls::after {
  background: #3aa6f5 none repeat scroll 0 0;
  bottom: -1px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 95%; }

.y-feature_post img.img-responsive, .y-blog_listing img {
  border: 10px solid #ffffff;
  box-shadow: 0 0 3px #d0d0d0;
  -moz-box-shadow: 0 0 3px #d0d0d0;
  -webkit-box-shadow: 0 0 3px #d0d0d0; }

.y-blogs .y-feature_post {
  width: 100%;
  margin-top: 50px; }
  .y-blogs .y-feature_post .y-blog_info h5 {
    margin-top: 30px; }
  .y-blogs .y-feature_post .y-blog_info h3 {
    color: #002E5B;
    position: relative; }
  .y-blogs .y-feature_post .y-blog_info p {
    font-size: 14px; }
  .y-blogs .y-feature_post .y-blog_info a.y-button, .y-blogs .y-feature_post .y-blog_info .y-footer .y-special_offer ul li a.y-read_more, .y-footer .y-special_offer ul li .y-blogs .y-feature_post .y-blog_info a.y-read_more, .y-blogs .y-feature_post .y-blog_info .y-home_blog_content a.y-btn, .y-home_blog_content .y-blogs .y-feature_post .y-blog_info a.y-btn {
    text-align: center;
    background: #3aa6f5;
    color: #FFFFFF;
    padding: 8px 10px;
    margin-bottom: 15px; }
  .y-blogs .y-feature_post .y-blog_info a.y-button:hover, .y-blogs .y-feature_post .y-blog_info .y-footer .y-special_offer ul li a.y-read_more:hover, .y-footer .y-special_offer ul li .y-blogs .y-feature_post .y-blog_info a.y-read_more:hover, .y-blogs .y-feature_post .y-blog_info .y-home_blog_content a.y-btn:hover, .y-home_blog_content .y-blogs .y-feature_post .y-blog_info a.y-btn:hover {
    color: #FFFFFF; }
  .y-blogs .y-feature_post .y-blog_info .y-blog_general_info li span, .y-blogs .y-feature_post .y-blog_info .y-blog_general_info li a {
    text-transform: capitalize; }
  .y-blogs .y-feature_post .y-blog_info .y-blog_general_info li i {
    top: 5px; }
.y-blogs .y-info_pop {
  background-color: #002E5B;
  max-width: 200px;
  padding: 10px 15px;
  position: absolute;
  right: 50px;
  text-align: left;
  top: 60px;
  width: 100%; }
  .y-blogs .y-info_pop span, .y-blogs .y-info_pop i {
    font-size: 13px;
    color: #FFFFFF; }
  .y-blogs .y-info_pop i {
    top: 2px;
    position: relative;
    margin-right: 5px; }
  .y-blogs .y-info_pop a {
    margin: auto;
    color: #FFFFFF;
    font-size: 14px;
    display: block;
    line-height: 20px;
    font-weight: 600; }
  .y-blogs .y-info_pop a:hover {
    color: #3AA6F5; }
.y-blogs .y-blog_listing {
  margin-top: 60px; }

@media (max-width: 1200px) {
  .y-section_content {
    padding: 0; }

  form.y-form .y-select_half input {
    width: 49%; }

  .y-boat_type .y-boat_info span {
    right: 5px; } }
@media (max-width: 990px) {
  .y-section_content h2 br, .y-section_content h4 br, .y-section_content p br {
    display: none; }

  .y-blog_listing {
    margin: auto; }

  .y-blogs .y-info_pop {
    left: 5px; }

  .y-boat_type .y-boat_imgs img {
    max-width: 80px;
    margin: 0 10px; } }
@media (max-width: 767px) {
  .y-dest_list_single img {
    margin: auto; }

  .y-dest_list_single h2 {
    padding-top: 15px;
    clear: both; }

  .y-blog_listing div.y-info_pop {
    left: 0;
    right: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0; }
  .y-blog_listing img {
    margin: auto;
    margin-bottom: 25px; }

  .y-popular_destination h3 {
    margin-top: 20px; }

  .y-boat_carousel {
    max-width: 300px;
    margin: auto; }

  .y-feature_post img {
    margin: auto; }

  .y-boat_type .owl-controls::after {
    width: 85%; }

  .y-our_services .y-service_inner {
    max-width: 324px;
    margin: auto auto 20px; }

  .y-dest_list_single li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px; }

  .y-blogs .y-feature_post {
    margin-left: auto;
    margin-right: auto; }

  .y-boat_type .y-boat_imgs img {
    margin: 10px; } }
/* FOOTER */
.y-footer {
  background: #FFFFFF;
  padding: 60px 0 0; }
  .y-footer h5 {
    color: #000000;
    text-transform: capitalize;
    padding-bottom: 10px; }
    .y-footer h5 i, .y-footer h5 span {
      float: left;
      margin-right: 10px; }
    .y-footer h5 span {
      font-size: 18px; }
  .y-footer h5:after {
    clear: both;
    display: block;
    content: ""; }
  .y-footer p, .y-footer a, .y-footer span {
    color: #464646;
    font-size: 13px; }
  .y-footer ul {
    list-style: square;
    padding-left: 23px; }
    .y-footer ul li {
      line-height: 34px; }
      .y-footer ul li strong {
        font-weight: 600;
        font-size: 13px;
        color: #303030; }
  .y-footer hr {
    border-color: #d6d6d6; }
  .y-footer p.text-center {
    margin-bottom: 0; }
  .y-footer a:hover {
    color: #002E5B; }
  .y-footer .y-special_offer ul {
    list-style: none;
    padding-left: 5px; }
    .y-footer .y-special_offer ul li {
      position: relative; }
      .y-footer .y-special_offer ul li a {
        font-weight: 600;
        color: #303030; }
      .y-footer .y-special_offer ul li p {
        margin-top: 0;
        font-size: 13px; }
      .y-footer .y-special_offer ul li .y-read_more {
        font-size: 14px;
        padding: 0 10px; }
  .y-footer .y-social_links {
    text-align: right; }
    .y-footer .y-social_links p {
      margin-top: 5px; }
    .y-footer .y-social_links a {
      font-size: 18px;
      display: inline-block;
      margin-left: 5px; }
  .y-footer p.text-left {
    margin: auto; }
  .y-footer .y-footer_strip {
    background: #FFF;
    padding: 8px 0;
    margin-top: 50px;
    box-shadow: 0 0 5px #CCCCCC;
    -moz-box-shadow: 0 0 5px #CCCCCC;
    -webkit-box-shadow: 0 0 5px #CCCCCC; }
    .y-footer .y-footer_strip p {
      margin: auto;
      color: #002E5B; }
    .y-footer .y-footer_strip .fa {
      color: #002E5B; }
  .y-footer .y-form button {
    background: #e1e1e1 none repeat scroll 0 0;
    color: #303030;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    max-width: 100px;
    padding: 10px;
    text-transform: uppercase; }
  .y-footer .y-form button:hover {
    color: #FFFFFF; }
  .y-footer .y-social a {
    display: inline-block;
    font-size: 22px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px; }
    .y-footer .y-social a i {
      color: #FFFFFF; }
  .y-footer form.y-form button {
    border: none;
    border-left: 5px solid #002E5B; }

@media (max-width: 990px) {
  .y-footer .col-lg-3 {
    margin-bottom: 50px; } }
@media (max-width: 767px) {
  .y-footer h5 {
    margin-bottom: 10px;
    padding-bottom: 0; }

  .y-footer .text-left, .y-footer .y-social_links {
    text-align: center; }

  .y-footer .y-footer_strip p {
    margin: 8px auto; } }
/* COMMENT */
.y-client_says_section {
  background: transparent url("../../images/clients_bg.jpg") no-repeat fixed 0 0/cover; }
  .y-client_says_section .owl-stage-outer {
    margin-top: 50px; }
  .y-client_says_section h2 {
    color: #FFFFFF; }

.y-client_testimonials {
  text-align: center;
  color: #FFFFFF; }
  .y-client_testimonials p {
    max-width: 80%;
    margin: auto auto 30px; }

/* INDEX 01 */
.y-header_01 .header {
  padding: 10px 0 15px; }
  .y-header_01 .header .fa {
    color: #002E5B; }
  .y-header_01 .header .fa:hover {
    color: #FFFFFF; }
  .y-header_01 .header .rmm.style ul li > a:not(:only-child)::before {
    top: 17px;
    right: 10px; }
.y-header_01 .y-header_outer nav {
  border: none;
  margin-top: 35px; }
.y-header_01 address {
  text-align: right;
  margin-bottom: 0; }
.y-header_01 .y-menu_outer {
  margin-top: 0;
  border-top: 0; }
.y-header_01 .flexnav li a {
  padding: 0.96em 0.36em; }
.y-header_01 .rmm.style ul li a {
  font-size: 13px;
  margin-right: 0;
  padding: 1.2em 1.5em; }
.y-header_01 .rmm.style ul ul li a {
  padding: 6px 50px 6px 15px;
  margin-right: 0; }
.y-header_01 .y-sticky .rmm.style ul li > a:not(:only-child)::before {
  top: 18px; }
.y-header_01 .y-top_info_part > * {
  display: inline-block;
  margin-left: 10px;
  padding-top: 0 !important;
  text-align: right;
  position: static; }
.y-header_01 .y-top_info_part {
  text-align: right; }
  .y-header_01 .y-top_info_part address {
    margin-bottom: 0;
    text-align: right; }
.y-header_01 .rmm-toggled {
  bottom: auto;
  top: auto;
  position: static; }
.y-header_01 .rmm-toggled-button {
  float: right;
  margin: auto; }
.y-header_01 .rmm.style ul.rmm-mobile {
  background: #ffffff none repeat scroll 0 0;
  position: absolute;
  left: 0;
  margin-top: 18px;
  z-index: 9999; }
.y-header_01 .rmm.style > ul > li > a {
  color: #002E5B; }

.y-sticky .y-header_01 .header {
  padding: 0; }
.y-sticky .y-header_01 .flexnav li a {
  margin-right: 10px;
  padding: 0.96em 0.7em 0.96em 0; }

#y-home_slider h1 {
  bottom: 100px;
  color: #ffffff;
  font-size: 54px;
  font-weight: bold;
  left: 0;
  margin: auto;
  max-height: 288px;
  position: absolute;
  right: 0;
  text-align: center;
  text-shadow: 0 0 3px #646464; }

@media (max-width: 1400px) {
  #y-home_slider h1 {
    bottom: 20px; }

  .bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
    bottom: 5px; } }
@media (min-width: 1024px) {
  .y-header_01 .y-menu_outer .rmm.style {
    float: right; } }
@media (max-width: 767px) {
  #y-home_slider {
    bottom: 30px; }
    #y-home_slider h1 {
      font-size: 18px; }

  .y-header_01 {
    background: url("../../images/banner.jpg"); }
    .y-header_01 .y-logo img {
      margin: auto;
      max-height: 80px; }
    .y-header_01 .col-lg-8 {
      padding-top: 0; }
    .y-header_01 .y-header_outer .row > .col-sm-2 {
      text-align: center;
      margin-bottom: 15px; }
    .y-header_01 .rmm-toggled, .y-header_01 .y-header_outer .y-social {
      position: static; }
    .y-header_01 .y-top_info_part {
      text-align: center; }
    .y-header_01 .rmm.style ul {
      margin-top: 0; } }
/* INDEX 02 */
.y-home_02 .y-slider_form {
  position: relative; }
  .y-home_02 .y-slider_form > div#y-header_form {
    position: absolute;
    top: 0;
    right: 10%; }
.y-home_02 .y-header_form {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  max-width: 400px;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 999; }
  .y-home_02 .y-header_form h3 {
    margin-bottom: 30px;
    color: #002E5B;
    font-size: 24px; }
  .y-home_02 .y-header_form div.col-lg-12 button {
    color: #002E5B;
    border-color: #002E5B; }
    .y-home_02 .y-header_form div.col-lg-12 button i {
      position: relative;
      top: 5px;
      margin-right: 8px; }
  .y-home_02 .y-header_form div.col-lg-12 button:hover {
    color: #FFFFFF; }
  .y-home_02 .y-header_form div.col-lg-12 > div {
    padding-left: 35px;
    position: relative;
    margin-bottom: 15px; }
    .y-home_02 .y-header_form div.col-lg-12 > div input {
      margin-bottom: 0; }
    .y-home_02 .y-header_form div.col-lg-12 > div i {
      color: #003d70;
      font-size: 26px;
      left: 0;
      position: absolute;
      top: 7px;
      z-index: 999; }
.y-home_02 .y-book_today .y-section_inner {
  padding-top: 10px; }
.y-home_02 .y-our_services {
  text-align: center; }
  .y-home_02 .y-our_services i {
    font-size: 34px; }
  .y-home_02 .y-our_services .y-servie_info {
    background: none !important;
    position: static;
    margin-bottom: 25px; }
    .y-home_02 .y-our_services .y-servie_info span {
      text-transform: uppercase;
      color: #002E5B;
      font-family: "Roboto Slab";
      font-size: 16px;
      display: block;
      margin: 20px auto 15px; }
    .y-home_02 .y-our_services .y-servie_info p {
      margin: auto;
      color: #464646; }

/* INDEX 05 */
.y-header_05 .header, .y-inner_header .header {
  padding: 0 0 5px;
  background: #FFFFFF; }
  .y-header_05 .header .fa, .y-inner_header .header .fa {
    color: #002E5B; }
  .y-header_05 .header .fa:hover, .y-inner_header .header .fa:hover {
    color: #FFFFFF; }
  .y-header_05 .header .rmm.style ul li > a:not(:only-child)::before, .y-inner_header .header .rmm.style ul li > a:not(:only-child)::before {
    top: 17px;
    right: 10px; }
.y-header_05 .y-header_strip .y-logo, .y-inner_header .y-header_strip .y-logo {
  margin-top: 0; }
.y-header_05 .y-header_outer nav, .y-inner_header .y-header_outer nav {
  border: none;
  margin-top: 35px; }
.y-header_05 address, .y-inner_header address {
  text-align: right;
  margin-bottom: 0; }
.y-header_05 .y-menu_outer, .y-inner_header .y-menu_outer {
  margin-top: 0;
  border-top: 0; }
.y-header_05 .flexnav li a, .y-inner_header .flexnav li a {
  padding: 0.96em 0.36em; }
.y-header_05 .rmm.style ul li a, .y-inner_header .rmm.style ul li a {
  font-size: 13px;
  margin-right: 0;
  padding: 1.2em 1.5em; }
.y-header_05 .rmm.style ul ul li a, .y-inner_header .rmm.style ul ul li a {
  padding: 6px 50px 6px 15px;
  margin-right: 0; }
.y-header_05 .y-sticky .rmm.style ul li > a:not(:only-child)::before, .y-inner_header .y-sticky .rmm.style ul li > a:not(:only-child)::before {
  top: 18px; }
.y-header_05 .y-top_info_part > *, .y-inner_header .y-top_info_part > * {
  display: inline-block;
  margin-left: 10px;
  padding-top: 0 !important;
  text-align: right;
  position: static; }
.y-header_05 .y-top_info_part, .y-inner_header .y-top_info_part {
  text-align: right; }
  .y-header_05 .y-top_info_part address, .y-inner_header .y-top_info_part address {
    margin-bottom: 0;
    text-align: right; }
.y-header_05 .rmm-toggled, .y-inner_header .rmm-toggled {
  bottom: auto;
  top: auto;
  position: static; }
.y-header_05 .rmm-toggled-button, .y-inner_header .rmm-toggled-button {
  float: right;
  margin: auto; }
.y-header_05 .rmm.style ul.rmm-mobile, .y-inner_header .rmm.style ul.rmm-mobile {
  background: #ffffff none repeat scroll 0 0;
  position: absolute;
  left: 0;
  margin-top: 18px;
  z-index: 9999; }
.y-header_05 .rmm.style > ul > li > a, .y-inner_header .rmm.style > ul > li > a {
  color: #002E5B; }

.y-product_home {
  padding-top: 50px;
  clear: both; }

.y-sticky .y-header_05 .header {
  padding: 0; }
.y-sticky .y-header_05 .flexnav li a {
  margin-right: 10px;
  padding: 0.96em 0.7em 0.96em 0; }

.y-inner_header .y-sticky {
  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%;
  left: 0; }
  .y-inner_header .y-sticky .header {
    padding: 0; }
  .y-inner_header .y-sticky .flexnav li a {
    margin-right: 10px;
    padding: 0.96em 0.7em 0.96em 0; }

@media (min-width: 1024px) {
  .y-header_05 .y-menu_outer .rmm.style, .y-inner_header .y-menu_outer .rmm.style {
    float: right; } }
@media (max-width: 767px) {
  .y-header_05 {
    background: url("../../images/header_bg_05.jpg"); }
    .y-header_05 .y-logo img {
      margin: auto;
      max-height: 80px; }
    .y-header_05 .col-lg-8 {
      padding-top: 0; }
    .y-header_05 .y-header_outer .row > .col-sm-2 {
      text-align: center;
      margin-bottom: 15px; }
    .y-header_05 .rmm-toggled, .y-header_05 .y-header_outer .y-social {
      position: static; }
    .y-header_05 .y-top_info_part {
      text-align: center; }
    .y-header_05 .rmm.style ul {
      margin-top: 0; } }
/* INDEX 06 */
.y-header_06 .y-menu_outer {
  background: none !important; }

.y-header_06 .header {
  padding: 0 0 5px;
  background: none; }
  .y-header_06 .header .fa {
    color: #002E5B; }
  .y-header_06 .header .fa:hover {
    color: #FFFFFF; }
  .y-header_06 .header .rmm.style ul li > a:not(:only-child)::before {
    top: 17px;
    right: 10px; }
.y-header_06 .y-header_strip .y-logo {
  margin-top: 0; }
.y-header_06 .y-header_outer nav {
  border: none;
  margin-top: 35px; }
.y-header_06 address {
  text-align: right;
  margin-bottom: 0; }
.y-header_06 .y-menu_outer {
  margin-top: 0;
  border-top: 0; }
.y-header_06 .flexnav li a {
  padding: 0.96em 0.36em; }
.y-header_06 .rmm.style ul li a {
  font-size: 13px;
  margin-right: 0;
  padding: 1.2em 1.5em; }
.y-header_06 .rmm.style ul ul li a {
  padding: 6px 50px 6px 15px;
  margin-right: 0; }
.y-header_06 .y-sticky .rmm.style ul li > a:not(:only-child)::before {
  top: 18px; }
.y-header_06 .y-top_info_part > * {
  display: inline-block;
  margin-left: 10px;
  padding-top: 0 !important;
  text-align: right;
  position: static; }
.y-header_06 .y-top_info_part {
  text-align: right; }
  .y-header_06 .y-top_info_part address {
    margin-bottom: 0;
    text-align: right; }
.y-header_06 .rmm-toggled {
  bottom: auto;
  top: auto;
  position: static; }
.y-header_06 .rmm-toggled-button {
  float: left;
  margin: auto; }
.y-header_06 .rmm.style ul.rmm-mobile {
  background: #ffffff none repeat scroll 0 0;
  position: absolute;
  left: 0;
  margin-top: 18px;
  z-index: 9999; }
.y-header_06 .rmm.style > ul > li > a {
  color: #002E5B; }

.y-product_home {
  padding-top: 50px;
  clear: both; }

.y-sticky .y-header_06 .header {
  padding: 0; }
.y-sticky .y-header_06 .flexnav li a {
  margin-right: 10px;
  padding: 0.96em 0.7em 0.96em 0; }

@media (min-width: 1024px) {
  .y-header_06 .y-menu_outer .rmm.style {
    float: right; } }
@media (max-width: 767px) {
  .y-header_06 {
    background: url("../../images/header_bg_06.jpg");
    background-size: cover; }
    .y-header_06 .y-logo img {
      margin: auto;
      max-height: 80px; }
    .y-header_06 .col-lg-8 {
      padding-top: 0; }
    .y-header_06 .y-header_outer .row > .col-sm-2 {
      text-align: center;
      margin-bottom: 15px; }
    .y-header_06 .rmm-toggled, .y-header_06 .y-header_outer .y-social {
      position: static; }
    .y-header_06 .y-top_info_part {
      text-align: center; }
    .y-header_06 .rmm.style ul {
      margin-top: 0; } }
/* INNER PAGE */
.y-landing > .y-inner_header {
  background-image: url("../../images/landing_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  padding-bottom: 375px; }

.y-landing .y-inner_header .header {
  background: none; }

.y-single_info_inner {
  background-color: #ffffff;
  margin-top: -40px;
  padding: 35px 5px 35px 20px; }
  .y-single_info_inner .col-sm-8 {
    padding-right: 30px; }
  .y-single_info_inner ul {
    list-style: outside none square;
    margin: 0;
    padding-left: 18px; }
  .y-single_info_inner li {
    line-height: 32px;
    /*display: inline-block; margin: 0 10px;*/ }
  .y-single_info_inner li > span {
    text-transform: capitalize; }
  .y-single_info_inner li > span.y-info_val {
    float: right; }
  .y-single_info_inner .y-dest_list h3 {
    color: #002E5B; }
    .y-single_info_inner .y-dest_list h3 a {
      color: #002E5B; }
  .y-single_info_inner .y-dest_list h3 {
    margin-bottom: 20px; }
  .y-single_info_inner .y-dest_list ul {
    margin: 15px 0 20px; }
  .y-single_info_inner .y-dest_list h4, .y-single_info_inner .y-dest_list li {
    color: #464646; }

@media (min-width: 1200px) {
  .y-single_info_inner {
    margin-top: -100px; }

  .y-landing > .y-inner_header {
    padding-bottom: 500px; } }
@media (min-width: 1400px) {
  .y-single_info_inner {
    margin-top: -100px; }

  .y-landing > .y-inner_header {
    padding-bottom: 600px; } }
.responsive-tabs-wrapper .y-yacht_intro_img img {
  top: -5px; }
.responsive-tabs-wrapper .y-yacht_intro_img {
  height: 250px; }

.y-slideshow {
  padding: 50px 0 40px; }

.y-footer {
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
  position: relative; }

.y-inner_page .y-sidebar {
  background: #002E5B;
  padding: 30px 15px; }
  .y-inner_page .y-sidebar h3 {
    color: #FFFFFF;
    font-size: 28px; }
  .y-inner_page .y-sidebar li a {
    color: #e3e3e3;
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600; }
  .y-inner_page .y-sidebar li a:hover {
    color: #3AA6F5; }
  .y-inner_page .y-sidebar .y-side_posts_text > a:hover {
    color: #3AA6F5; }

.y-side_bar_single {
  margin-bottom: 45px; }

.y-sidebar h4 {
  color: #000000;
  font: 700 15px/18px "Raleway","sans-serif";
  text-transform: uppercase;
  margin: auto auto 20px; }

.y-side_search input {
  background: #3aa6f5 none repeat scroll 0 0;
  border: 1px solid #3aa6f5;
  color: #ffffff;
  font: 400 16px/18px "Raleway",sans-serif;
  max-width: 400px;
  padding: 15px;
  width: calc(100% + 15px); }

.y-side_bar_cat_list {
  padding-left: 10px; }
  .y-side_bar_cat_list li {
    padding-bottom: 10px; }
    .y-side_bar_cat_list li a {
      font: 400 13px/20px "Raleway","sans-serif";
      color: #696969; }
    .y-side_bar_cat_list li span {
      float: right; }
  .y-side_bar_cat_list li:last-child {
    padding-bottom: 0; }

.y-side_text p {
  font: 400 13px/22px "Raleway","sans-serif";
  color: #696969; }

.y-side_tags_list {
  padding: 0; }
  .y-side_tags_list li {
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 3px;
    background: #ebebeb; }
    .y-side_tags_list li a {
      color: #202020;
      display: block;
      font: 400 14px/1 "Raleway",sans-serif;
      padding: 8px 12px; }
  .y-side_tags_list li:hover a {
    color: #FFFFFF; }

.y-side_posts_list > div {
  margin-bottom: 20px;
  position: relative;
  padding-left: 100px;
  padding-top: 0;
  min-height: 80px; }
.y-side_posts_list > div img {
  position: absolute;
  top: 0;
  left: 0; }
.y-side_posts_list > div:last-child {
  margin-bottom: 0; }

.y-side_posts_text > a {
  color: #FFFFFF;
  font: 700 15px/18px "Raleway","sans-serif";
  text-transform: uppercase;
  padding-bottom: 2px;
  display: inline-block; }
.y-side_posts_text ul {
  padding-left: 0; }

.y-single_tweet {
  font: 400 13px/22px "Raleway","sans-serif";
  color: #696969; }
  .y-single_tweet > p {
    line-height: 18px;
    margin: auto;
    font-size: 13px;
    display: inline; }

.y-blog_detail {
  margin: auto !important; }
  .y-blog_detail li {
    padding-right: 10px; }
  .y-blog_detail li:after {
    position: absolute;
    content: "|";
    top: 0;
    right: 2px;
    font-style: normal;
    font-family: "Raleway"; }
  .y-blog_detail li, .y-blog_detail li a {
    display: inline-block;
    color: #e6e6e6;
    font: italic 12px/16px "Raleway","sans-serif";
    position: relative; }
  .y-blog_detail li:last-child:after {
    content: ""; }

.textwidget p {
  color: #FFFFFF; }

.tagcloud a {
  background: #3AA6F5 none repeat scroll 0 0;
  color: #FFF;
  display: inline-block;
  font: 400 14px/1 "Raleway",sans-serif !important;
  margin-bottom: 5px;
  margin-right: 3px;
  padding: 8px 12px; }

.wp-caption {
  max-width: 100%; }

.widget img, .widget select {
  max-width: 100%;
  height: auto; }

.y-side_bar_single > ul {
  padding-left: 15px; }

.y-side_bar_single > ul > li {
  padding-bottom: 10px; }

.widget_categories a {
  float: left; }

.menu ul {
  padding-left: 20px; }

.menu {
  padding-left: 10px; }

ul.menu > li {
  padding-bottom: 10px; }

table th {
  background: #f8f8f8 none repeat scroll 0 0;
  color: #000000;
  font-size: 13px;
  font-weight: bold;
  padding: 7px;
  text-align: left;
  text-shadow: 0 1px 0 #ffffff; }

table th, table td {
  border: 1px solid #dce3eb;
  vertical-align: top; }

table td {
  background: #ffffff none repeat scroll 0 0;
  color: #484848;
  padding: 5px; }

table {
  border: 1px solid #dce3eb;
  border-collapse: collapse;
  margin: 15px 0;
  width: 100%; }

.widget select {
  padding: 8px;
  width: 100%; }

.comment-form .submit {
  background: transparent none repeat scroll 0 0;
  display: block;
  font: 400 17px/18px "Raleway","sans-serif";
  margin: 50px auto auto;
  padding: 15px 30px;
  text-transform: uppercase;
  width: 225px; }

.y-single_blog img {
  height: auto;
  max-width: 100%; }

.rssSummary {
  font-size: 14px;
  line-height: 24px; }

.tagcloud a:hover {
  background: #3AA6F5;
  color: #FFFFFF; }

/********BLOG DETAIL********/
.y-blog_detail_content {
  padding: 0; }

.y-blog_detail_bloger_img .y-glyp_icon span {
  display: block;
  padding: 20px 0 10px; }

.y-blog_detail_bloger_img .y-glyp_icon {
  background: #303030 none repeat scroll 0 0;
  color: #ffffff;
  fony-size: 22px; }

.y-blog_detail_info h2 {
  font-size: 28px;
  color: #002E5B;
  font-weight: 600;
  word-spacing: 2px;
  margin-bottom: 0; }

.y-blog_general_info {
  padding-left: 0 !important; }
  .y-blog_general_info li {
    display: inline-block;
    position: relative;
    padding-left: 15px; }
    .y-blog_general_info li i {
      position: absolute;
      left: 0;
      top: 8px;
      font-size: 18px; }
    .y-blog_general_info li span {
      border-right: 1px solid #E5E5E5;
      color: #949494;
      font: italic 400 12px/1 "Raleway",sans-serif;
      padding: 0 10px; }
    .y-blog_general_info li a {
      color: #949494; }
  .y-blog_general_info li:last-child span {
    border-right: none; }

.y-blog_detail_info {
  margin-bottom: 20px;
  padding-bottom: 10px; }

.y-blog_detail_text {
  padding-bottom: 30px; }

.y-blog_detail_text p {
  text-align: left; }

.y-alpha_text {
  height: 60px;
  width: 60px;
  background: #202020;
  color: #FFFFFF;
  font: 400 28px/60px "Raleway", sans-serif;
  text-align: center;
  display: block;
  margin: 8px 8px 0 0;
  float: left; }

.y-blog_detail_text > img {
  margin: 40px 0; }

.y-blog_detail_content .y-pagination {
  padding-top: 30px;
  padding-bottom: 50px; }

.y-blog_social_links ul li {
  display: inline-block; }

.y-blog_social_links ul li a {
  font-size: 14px;
  color: #202020;
  display: block;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px; }

.y-blog_social_links ul li a:hover {
  background: #002E5B;
  color: #FFFFFF; }

.y-home_blog_content ul {
  margin-bottom: 10px; }
.y-home_blog_content h3 {
  font-size: 24px;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 10px;
  color: #002E5B; }
.y-home_blog_content p {
  margin: 10px 0; }
.y-home_blog_content .y-btn {
  text-transform: capitalize;
  padding: 10px;
  border-color: #3aa6f5;
  background: #3aa6f5;
  color: #FFFFFF; }

.y-single_blog {
  margin-bottom: 50px; }

footer.post-meta {
  margin-bottom: 15px; }

footer.post-meta span {
  background-position: left 2px;
  background-repeat: no-repeat;
  background-size: auto 12px;
  color: #949494;
  display: inline-block; }

footer.post-meta span.post-categories {
  margin-left: 5px;
  padding-left: 16px; }

footer.post-meta span a {
  color: #949494;
  display: inline-block;
  font: 400 13px/1 "Raleway",sans-serif; }

@media (max-width: 767px) {
  .y-blog_detail_bloger_img {
    display: none; }

  .y-blog_detail_content {
    padding: 0 15px; } }
/* CATEGORY */
.y-product_listing > div {
  background: #FFFFFF;
  margin-bottom: 25px;
  padding: 5px; }
  .y-product_listing > div .m-prod_list_img {
    height: 260px;
    text-align: center;
    border-bottom: 1px solid;
    position: relative; }
    .y-product_listing > div .m-prod_list_img img {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0; }
  .y-product_listing > div .m-prod_list_text {
    height: 165px;
    padding: 28px 15px 0;
    border-top: 1px solid transparent; }
  .y-product_listing > div a.y-heading {
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;
    color: #002E5B; }
  .y-product_listing > div .y-adv_info_foot {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px); }
    .y-product_listing > div .y-adv_info_foot a {
      color: #002E5B; }
  .y-product_listing > div .y-fav_item {
    top: 10px;
    left: 10px;
    position: absolute; }

.y-yacht_intro_img {
  height: 260px;
  text-align: center;
  border-bottom: 1px solid #EFEFEF;
  position: relative;
  overflow: hidden; }
  .y-yacht_intro_img a:hover img {
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25); }
  .y-yacht_intro_img a {
    display: block;
    height: 100%;
    position: relative;
    width: 100%; }
  .y-yacht_intro_img img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }

.y-yacht_intro {
  padding: 15px;
  background: #f7f7f7; }
  .y-yacht_intro a {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600; }
  .y-yacht_intro span {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    font-style: italic;
    color: #002E5B; }
  .y-yacht_intro ul {
    padding-left: 0;
    list-style: none;
    padding: 10px 0;
    margin: 10px 0 15px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd; }
    .y-yacht_intro ul li {
      display: inline-block;
      color: #000000;
      position: relative;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase; }
    .y-yacht_intro ul img {
      max-width: 20px;
      margin-right: 10px;
      position: relative;
      top: -2px; }
    .y-yacht_intro ul i {
      position: relative;
      top: 3px;
      font-size: 20px; }
    .y-yacht_intro ul li:first-child {
      margin-right: 20px;
      padding-right: 20px; }
    .y-yacht_intro ul li:first-child:after {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      border-right: 1px solid #dddddd;
      content: "";
      display: block; }
  .y-yacht_intro .y-button, .y-yacht_intro .y-footer .y-special_offer ul li .y-read_more, .y-footer .y-special_offer ul li .y-yacht_intro .y-read_more, .y-yacht_intro .y-home_blog_content .y-btn, .y-home_blog_content .y-yacht_intro .y-btn {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    background: #3AA6F5;
    color: #FFFFFF; }

.y-product_filters ul {
  padding: 0;
  list-style: none; }
.y-product_filters .y-single_filter {
  margin-bottom: 50px; }
.y-product_filters > div:last-child {
  margin-bottom: 0; }
.y-product_filters .y-single_filter > span {
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 40px; }
.y-product_filters .y-single_filter li span {
  font: 16px "Raleway","sans serif";
  margin-bottom: 8px;
  display: block;
  color: #FFFFFF;
  cursor: pointer; }

.y-range_meter li {
  max-width: 48%;
  display: block;
  width: 100%;
  float: left; }
.y-range_meter li:nth-child(1) {
  margin-right: 2%; }
.y-range_meter span, .y-range_meter input {
  width: 100%;
  padding: 5px 0;
  height: 25px;
  color: #212121; }
.y-range_meter input {
  background: transparent;
  color: #FFFFFF;
  border: none;
  text-align: right; }

.y-product_filters, .y-login_popup {
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */ }
  .y-product_filters [type="checkbox"]:not(:checked), .y-product_filters [type="checkbox"]:checked, .y-login_popup [type="checkbox"]:not(:checked), .y-login_popup [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  .y-product_filters [type="checkbox"]:not(:checked) + label,
  .y-product_filters [type="checkbox"]:checked + label, .y-login_popup [type="checkbox"]:not(:checked) + label,
  .y-login_popup [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: block;
    font: 600 14px "Raleway","sans serif";
    margin-bottom: 10px;
    color: #e3e3e3;
    cursor: pointer; }
  .y-product_filters [type="checkbox"]:not(:checked) + label::before, .y-product_filters [type="checkbox"]:checked + label::before, .y-login_popup [type="checkbox"]:not(:checked) + label::before, .y-login_popup [type="checkbox"]:checked + label::before {
    border: 1px solid #e3e3e3;
    border-radius: 0;
    content: "";
    height: 17px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 17px; }
  .y-product_filters [type="checkbox"]:not(:checked) + label::after, .y-product_filters [type="checkbox"]:checked + label::after, .y-login_popup [type="checkbox"]:not(:checked) + label::after, .y-login_popup [type="checkbox"]:checked + label::after {
    background: #000 none repeat scroll 0 0;
    color: #fff;
    content: "\f00c";
    font-family: "fontawesome";
    font-size: 13px;
    height: 15px;
    left: 1px;
    line-height: 0.8;
    padding-left: 1px;
    padding-top: 2px;
    position: absolute;
    top: 3px;
    transition: all 0.2s ease 0s;
    width: 15px; }
  .y-product_filters [type="checkbox"]:not(:checked) + label:after, .y-login_popup [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .y-product_filters [type="checkbox"]:checked + label:after, .y-login_popup [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }

.y-nav_but {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 2px 2px #bfbfbf;
  color: #424242;
  display: inline-block;
  font: 16px "Raleway","sans serif";
  padding: 12px 15px;
  text-transform: uppercase; }

.y-pagination {
  padding-top: 0;
  clear: both; }
  .y-pagination ul {
    list-style: none;
    display: inline-block;
    padding: 0; }
  .y-pagination span {
    display: inline-block; }
  .y-pagination li {
    display: inline-block; }
  .y-pagination ul.y-pagination_num li {
    height: 48px;
    width: 48px;
    background: #FFFFFF;
    text-align: center;
    float: left; }
  .y-pagination ul.y-pagination_num li a {
    font: 16px "Raleway","sans serif";
    color: #212121;
    display: block;
    line-height: 48px; }
  .y-pagination ul.y-pagination_num a:hover, .y-pagination ul.y-pagination_num li a.y-active_page {
    background: #fafafa;
    color: #9e9e9e; }
  .y-pagination .y-pagination_count {
    text-align: right;
    line-height: 48px; }
    .y-pagination .y-pagination_count a, .y-pagination .y-pagination_count span {
      font: 16px "Raleway","sans serif"; }
  .y-pagination .y-pagination_count a {
    margin-left: 5px; }
  .y-pagination .y-pagination_count a.y-active_page_count, .y-pagination .y-pagination_count a:hover {
    color: #212121; }

.y-side_filter_but {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #FFF;
  font-size: 20px;
  display: block;
  cursor: pointer; }

.y-product_filter_side {
  position: absolute;
  right: -340px;
  top: 0; }

.y-product_filter_side.y-side_filter_open {
  right: 65px; }

#y-price_range > div:first-child {
  display: none; }

.noUi-background {
  margin-top: 10px;
  background: #3aa6f5; }

.noUi-handle {
  box-shadow: none; }

.noUi-handle.noUi-handle-upper {
  top: -16px; }

.noUi-horizontal .noUi-handle {
  cursor: pointer; }

.y-product_filters .y-single_filter li span:hover {
  color: #3AA6F5; }

@media (max-width: 1400px) {
  .y-product_listing_side > div:nth-child(3n+2) {
    margin: 0 15px; } }
@media (max-width: 1200px) {
  .y-product_listing_side > div {
    max-width: inherit; }

  .y-product_listing_side > div:nth-child(3n+2) {
    margin: auto; }

  .y-product_listing > div a.y-heading {
    font-size: 20px;
    line-height: 24px; }
    .y-product_listing > div a.y-heading br {
      display: none; }

  .y-product_listing_full {
    margin: auto; }

  .y-product_listing_full > div {
    max-width: 23.6%;
    margin: auto 15px 15px auto; } }
@media (max-width: 991px) {
  .y-product_listing > div {
    margin: auto; }

  .y-inner_container {
    max-width: calc(100% - 70px); }

  .y-pagination ul.y-pagination_num li {
    height: 42px;
    width: 42px; }

  .y-pagination .y-pagination_count, .y-pagination {
    text-align: center; }

  .y-pagination > div {
    margin: 5px 0; }

  .y-product_listing > div a.y-heading {
    font-size: 16px;
    line-height: 20px; }

  .y-product_listing_full > div {
    max-width: 100%;
    border: 8px solid #fafafa; }

  .y-product_filter_side {
    display: none; }

  .y-product_filter_side.y-side_filter_open {
    display: block; } }
@media (max-width: 767px) {
  .y-product_listing > div {
    margin: auto auto 15px;
    border: none; }

  .y-product_listing_full > div {
    max-width: inherit; } }
/* PRODUCT SINGLE */
.y-breadcrum {
  margin: 0 auto 50px; }
  .y-breadcrum h1 {
    margin: auto; }
  .y-breadcrum ul {
    padding: 0;
    list-style: none;
    background: #FFFFFF;
    padding: 20px 0; }
  .y-breadcrum li {
    display: inline-block; }
  .y-breadcrum a, .y-breadcrum span {
    color: #9e9e9e;
    font: 12px "Raleway","sans serif";
    text-transform: uppercase;
    letter-spacing: .5px;
    position: relative;
    padding: 0 10px; }
  .y-breadcrum a:after {
    content: "";
    background: url("../../images/wave.png") no-repeat;
    background-size: 100%;
    width: 10px;
    height: 10px;
    position: absolute;
    right: -6px;
    top: 3px; }
  .y-breadcrum span {
    color: #212121; }

.y-cat_top_filter {
  margin-bottom: 50px; }
  .y-cat_top_filter form {
    display: inline-block; }
  .y-cat_top_filter ul {
    padding: 0;
    list-style: none;
    display: inline-block;
    margin-left: 35px; }
  .y-cat_top_filter input {
    background: #eeeeee none repeat scroll 0 0;
    border: medium none;
    border-radius: 12px 0 0 12px;
    color: #9e9e9e;
    display: inline-block;
    font: 16px "Raleway","sans serif";
    max-width: 185px;
    padding: 14px 20px;
    width: 100%; }
  .y-cat_top_filter button {
    border-radius: 0 12px 12px 0;
    left: -5px;
    padding: 14px 18px;
    color: #424242;
    display: inline-block;
    position: relative;
    border: none;
    font-size: 16px;
    top: -.5px; }
  .y-cat_top_filter li {
    padding: 14px 20px;
    margin-right: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px; }
  .y-cat_top_filter li span {
    color: #424242;
    font: 16px "Raleway";
    text-transform: uppercase; }
  .y-cat_top_filter li i {
    font-size: 18px;
    margin-left: 18px;
    cursor: pointer; }
  .y-cat_top_filter img {
    height: 48px; }

.y-product_images {
  background: #FFFFFF; }
  .y-product_images #y-product_images > div.y-box:nth-child(1) {
    width: 100%; }
  .y-product_images #y-product_images > div.y-box:nth-child(2) {
    width: 319px;
    float: left; }
  .y-product_images #y-product_images > div.y-box:nth-child(3) {
    width: 168px;
    float: left; }

.y-product_text_details p {
  line-height: 24px; }
.y-product_text_details h5 {
  font-weight: 600;
  border-bottom: 3px solid;
  text-transform: uppercase;
  margin-top: 30px;
  color: #002E5B; }
.y-product_text_details h4 {
  text-transform: capitalize; }
.y-product_text_details ul {
  padding: 0;
  list-style: none; }
.y-product_text_details li {
  padding-bottom: 5px;
  font-family: "Raleway";
  font-size: 14px;
  color: #616161;
  line-height: 28px; }
.y-product_text_details li strong {
  font-weight: 600;
  color: #212121; }

.y-product_text_details ul.y-product_attr {
  margin: 30px auto; }

.y-price_box span {
  color: #002E5B;
  font-size: 26px;
  font-weight: 600;
  position: relative;
  top: 8px; }
.y-price_box a {
  padding: 10px 30px;
  text-transform: uppercase;
  margin-left: 20px;
  background: #3AA6F5;
  color: #FFFFFF; }

.y-relative_outer {
  padding-top: 100px;
  clear: both; }
  .y-relative_outer h3 {
    color: #002E5B; }
  .y-relative_outer .owl-carousel .owl-item img {
    width: auto;
    margin: auto; }

div.container {
  position: relative; }
  div.container .y-arrow_line {
    top: 35px;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    right: 30px;
    width: 74px;
    z-index: 999; }
  div.container .y-arrow_line i {
    position: absolute;
    display: block;
    font-size: 24px;
    top: -12px;
    cursor: pointer; }
  div.container .y-arrow_line i.y-left_anchor {
    left: -2px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg); }
  div.container .y-arrow_line i.y-right_anchor {
    right: -2px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg); }
  div.container .y-arrow_line i:hover {
    color: #002E5B; }

.y-relative_item {
  margin: 0; }
  .y-relative_item > div {
    padding-top: 10px; }
  .y-relative_item .y-relative_item > div > div.row {
    background: #FFFFFF; }
  .y-relative_item .y-relative_img {
    min-height: 240px;
    max-width: 95%;
    margin: auto;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 0 5px #c7c6c6;
    -webkit-box-shadow: 0 0 5px #c7c6c6;
    -moz-box-shadow: 0 0 5px #c7c6c6; }
  .y-relative_item .y-adv_info {
    position: relative;
    min-height: 165px;
    max-width: 95%;
    margin: auto;
    padding: 15px;
    border-top: none;
    box-shadow: 0 0 5px #c7c6c6 inset;
    -webkit-box-shadow: 0 0 5px #c7c6c6 inset;
    -moz-box-shadow: 0 0 5px #c7c6c6 inset; }
  .y-relative_item img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .y-relative_item a.y-heading {
    margin-bottom: 16px; }
  .y-relative_item .y-adv_info_foot {
    position: absolute;
    width: calc(100% - 30px);
    bottom: 15px; }

.y-product_details {
  margin-top: 25px; }
  .y-product_details ul {
    padding: 0;
    list-style: none; }
  .y-product_details li {
    float: left;
    margin-right: 15px; }
  .y-product_details li span {
    display: block;
    color: #9e9e9e;
    font-size: 12px;
    text-align: center; }
  .y-product_details .y-nav_but {
    height: 46px;
    width: 50px;
    color: #212121;
    font-size: 16px;
    padding: 0;
    line-height: 46px; }
  .y-product_details .y-attr_title {
    margin-bottom: 5px; }
  .y-product_details .y-color_attr {
    background: #019bff; }
  .y-product_details .y-price_box {
    margin-top: 15px; }
    .y-product_details .y-price_box span {
      font-size: 34px;
      font-weight: bold;
      position: relative;
      top: 5px;
      display: inline-block;
      margin-right: 100px; }
  .y-product_details .y-product_options {
    text-align: right;
    padding-top: 16px; }
    .y-product_details .y-product_options ul {
      display: inline-block; }
  .y-product_details .y-nav_but i {
    font-size: 18px; }

.owl-item {
  float: left; }

.owl-wrapper-outer {
  overflow: hidden; }

.owl-wrapper::after {
  clear: both;
  display: block;
  content: ""; }

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

@media (max-width: 1340px) {
  .y-product_images #y-product_images > div.y-box:nth-child(2) {
    width: 66%; }

  .y-product_images #y-product_images > div.y-box:nth-child(3) {
    width: 34%; }

  .y-product_details .y-price_box span {
    margin-right: 30px; } }
@media (max-width: 989px) {
  .y-footer_strip, .y-popular_items .y-adv_info {
    max-width: 95%; }

  div.container .y-arrow_line {
    bottom: 265px; }

  div.container .y-arrow_line {
    width: 125px;
    right: 0%; } }
@media (max-width: 767px) {
  .y-product_details > div {
    text-align: center; }
    .y-product_details > div ul {
      display: inline-block; }

  .y-product_details .y-product_options {
    text-align: center; } }
.y-fun_facts {
  background: url("../../images/fun_facts.jpg") no-repeat;
  background-size: cover;
  padding-bottom: 70px; }
  .y-fun_facts h2 {
    color: #FFFFFF; }

.y-fun_facts_block {
  padding: 50px 0 0;
  overflow: hidden; }

.y-fact_single > div {
  height: 260px; }

.y-fact_single > div > i {
  height: 60px;
  width: 60px;
  text-align: center;
  color: #FFFFFF;
  display: block;
  margin: 50px auto 30px;
  line-height: 60px;
  font-style: normal;
  font-size: 40px; }

.y-fact_single > div > span.y-fact_count {
  color: #FFFFFF;
  font: 700 40px/1 'Raleway', sans-serif;
  display: block;
  text-align: center; }

.y-fact_single > div > span.y-fact_title {
  color: #FFFFFF;
  font: 400 13px/1 'Raleway', sans-serif;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px; }

.container .y-our_team h2 {
  color: #002E5B; }
.container .y-our_team .y-arrow_line {
  top: -25px; }

.y-our_team_list {
  margin-top: 50px;
  position: relative; }

.y-our_team_list .y-team_img {
  border: 1px solid #e5e5e5;
  padding: 8px;
  width: 100%;
  max-height: 254px;
  max-width: 254px;
  -webkiy-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.y-our_team_list .y-team_name {
  color: #000000;
  font: 700 15px/1 'Raleway', sans-serif;
  text-transform: uppercase;
  display: block;
  letter-spacing: 2px;
  text-align: center; }

.y-our_team_list .y-team_name span {
  margin: 20px 0 10px;
  display: block; }

.y-our_team_list .y-team_post {
  color: #000000;
  font: 400 11px/1 'Raleway', sans-serif;
  display: block;
  letter-spacing: 1px;
  text-align: center;
  font-style: italic; }

.y-slide_controls {
  text-align: center;
  padding: 80px 0 45px; }

.y-our_team_list .item {
  position: relative; }

.y-our_team_list .item:after {
  position: absolute;
  max-width: 254px;
  width: 30px;
  height: 1px;
  background: #cfcfcf;
  content: "";
  margin: auto;
  left: 0;
  right: 0;
  bottom: -20px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.y-our_team_list .item:hover:after {
  width: 100%;
  background: #002E5B; }

.y-our_team_list .item:hover img.y-team_img {
  border-color: #002E5B; }

.y-our_team_list .item:hover .y-team_name {
  color: #002E5B; }

.y-slide_controls a {
  display: inline-block;
  border: 1px solid #002E5B;
  color: #002E5B;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  line-height: 28px; }

.y-slide_controls a:hover {
  background: #002E5B;
  border: 1px solid #002E5B;
  color: #FFF; }

.y-our_team_carousel {
  overflow: hidden; }

.y-our_team_carousel .owl-wrapper-outer {
  min-height: 335px; }

.y-our_team_overlay {
  margin: auto;
  max-width: 254px;
  position: relative; }
  .y-our_team_overlay li {
    display: inline-block;
    margin: 0 10px; }

.y-our_team_social {
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
  bottom: 8px;
  height: calc(100% - 16px);
  left: 8px;
  max-height: 36px;
  max-width: 254px;
  opacity: 0;
  position: absolute;
  transition: all 1s ease 0s;
  width: 93%; }

.y-our_team_social > ul {
  bottom: 0;
  height: 100%;
  list-style: outside none none;
  margin: auto;
  max-height: 100px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  vertical-align: middle;
  width: 100%; }

.y-our_team_social a {
  color: #ffffff;
  font-size: 16px;
  margin: 8px 0; }

.y-our_team_social a:hover {
  color: #002E5B; }

.y-our_team_list .item:hover .y-our_team_social {
  opacity: 1; }

@media (min-width: 481px) and (max-width: 767px) {
  .y-our_team_carousel .owl-wrapper-outer {
    min-height: 240px; } }
.y-corporate_block li {
  color: #808080; }

/* CONTACT */
.y-map {
  height: 300px; }

.y-contact .y-contact_form {
  padding: 15px;
  background: #EFEFEF;
  margin-top: 30px; }
.y-contact h3 {
  color: #002E5B; }
.y-contact form.y-form button {
  border-color: #002E5B;
  color: #002E5B; }
.y-contact form.y-form button:hover {
  color: #FFFFFF; }
.y-contact textarea {
  height: 100px; }
.y-contact address {
  color: #1c1c1c;
  line-height: 26px;
  font-size: 16px; }
  .y-contact address span {
    font-weight: 600; }
  .y-contact address a {
    color: #002E5B; }
.y-contact abbr[title] {
  border: none; }
.y-contact ul li {
  color: #1c1c1c; }
.y-contact .y-contact_side {
  background-color: #efefef;
  padding: 25px 20px; }
  .y-contact .y-contact_side > div {
    margin-bottom: 30px; }
  .y-contact .y-contact_side > div:last-child {
    margin-bottom: 0; }

.y-contact .y-contact i {
  position: relative;
  top: 6px;
  margin-right: 3px; }

.y-contact_01 .y-contact i {
  position: relative;
  top: 6px;
  margin-right: 3px; }
.y-contact_01 .y-map {
  height: 400px; }
.y-contact_01 .y-contact .y-contact_side {
  background: none; }

.y-contact_02 .y-contact i {
  position: relative;
  top: 6px;
  margin-right: 3px; }
.y-contact_02 .y-contact .y-contact_side {
  background: none; }
.y-contact_02 .y-map {
  height: 200px;
  margin-bottom: 20px; }
.y-contact_02 .y-contact .y-social {
  margin-top: 30px; }
  .y-contact_02 .y-contact .y-social a {
    display: block;
    height: 50px;
    margin-bottom: 10px;
    line-height: 30px;
    padding: 10px;
    text-align: center; }
  .y-contact_02 .y-contact .y-social a, .y-contact_02 .y-contact .y-social i {
    color: #FFFFFF; }
  .y-contact_02 .y-contact .y-social i {
    font-size: 22px;
    top: 2px; }
  .y-contact_02 .y-contact .y-social a:hover {
    opacity: .8; }

.y-footer > .container .row > div:nth-child(1) {
  background: url("../../images/contact_bg.png") no-repeat;
  background-size: 100%; }

.y-tw {
  background: #55acee; }

.y-gp {
  background: #dc4e41; }

.y-fb {
  background: #3b5998; }

.y-linkd {
  background: #0177B5; }

.header .y-tw:hover {
  background: #55acee; }
.header .y-gp:hover {
  background: #dc4e41; }
.header .y-fb:hover {
  background: #3b5998; }
.header .y-linkd:hover {
  background: #0177B5; }

.y-yt {
  background: #cc0000;
  display: none !important; }

.y-insta {
  background: #4b7699;
  display: none !important; }

.y-boxed {
  background: transparent url("../../images/home_4_bg.jpg") no-repeat fixed 0 0/cover;
  background-position: bottom; }
  .y-boxed > section {
    background: #FFFFFF; }
  .y-boxed > * {
    margin: auto;
    max-width: 1240px; }
  .y-boxed .y-header_strip .header {
    max-width: 1240px; }
  .y-boxed .parallax-image-wrapper {
    right: 0;
    margin: auto; }

/* minimal styles for that navi */
.y-menu_outer {
  margin-top: 0;
  background: #002E5B;
  position: relative;
  top: 15px;
  z-index: 9999;
  padding-left: 15px; }
  .y-menu_outer .y-social {
    position: absolute;
    right: 20px;
    top: 10px; }

.rmm ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative; }

.rmm ul:after {
  content: "";
  clear: both;
  display: block; }

.rmm ul li {
  float: left; }

.rmm ul li:hover > ul {
  display: block; }

.rmm ul li:hover a {
  color: #fff; }

.rmm ul li a {
  color: #fff;
  display: block;
  text-decoration: none; }

.rmm ul ul {
  display: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%; }

.rmm ul ul li {
  float: none;
  position: relative; }

.rmm ul ul li a {
  color: #fff; }

.rmm ul ul li a:hover {
  background: #002E5B;
  color: #FFFFFF !important; }

.rmm ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%; }

/* mobile menu header button */
.rmm-toggled {
  min-height: 50px;
  margin: 0 auto;
  top: -34px;
  display: none;
  position: absolute;
  right: 0; }

.rmm-closed ~ .rmm-mobile {
  display: none !important; }

.rmm-toggled-controls {
  width: 100%; }

.rmm-toggled-title {
  width: 60%;
  float: left;
  display: none;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  padding: 8px 0 5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  padding-left: 20px; }

.rmm-toggled-button {
  width: 20%;
  float: left;
  margin-top: 3px;
  display: block;
  width: 32px;
  padding: 0 !important;
  margin: 13px 10px 0 0;
  float: right; }

.rmm-toggled-button span {
  float: left;
  display: block;
  margin: 2px 6px;
  height: 3px;
  background: #3aa6f5;
  width: 20px; }

/* normalny back button visible only on mobiles  */
.rmm .rmm-back {
  display: none;
  font-size: 12px; }

.rmm .rmm-back:after {
  display: none; }

.rmm .rmm-back a:after {
  content: "\f107";
  display: inline-block;
  float: left;
  font-family: fontawesome;
  font-size: 20px;
  position: relative;
  right: 2px;
  top: 0;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg); }

.rmm-mobile .rmm-back {
  display: block; }

/* mobile menu list */
.rmm-mobile {
  width: 100%; }

.rmm-mobile ul {
  width: 100%;
  position: inherit !important; }

.rmm-mobile li {
  width: 100%; }

/* mobile menu js action */
.rmm-dropdown {
  display: block !important; }

.rmm-mobile .rmm-subview {
  display: block !important;
  width: 100%; }

.rmm-mobile .rmm-subover-hidden {
  display: none !important; }

.rmm-mobile .rmm-subover-visible {
  display: block; }

.rmm-mobile .rmm-subover-header {
  display: none !important;
  padding: 0;
  margin: 0; }

/* mobile menu content */
.rmm-mobile .rmm-submenu {
  position: relative;
  left: 0px; }

.rmm .rmm-mobile li:hover > ul {
  display: none; }

/* arrow down for wide screen */
.rmm.style {
  text-align: left;
  font-family: "Raleway", "sans-serif";
  padding: 0 0 0; }

.rmm.style ul {
  display: inline-table;
  text-align: left; }

.rmm.style ul li {
  text-align: left; }

.rmm.style ul ul {
  display: none;
  margin: 0;
  min-width: 140px;
  background: #FFFFFF;
  border-radius: 0px;
  padding: 0;
  position: absolute;
  z-index: 9999; }

.rmm.style ul li a {
  color: #FFFFFF;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 12px 8px;
  margin-right: 15px;
  display: block; }

.rmm.style ul ul li a {
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  font-weight: normal;
  color: #002E5B;
  text-transform: capitalize;
  padding: 6px 50px 6px 15px;
  margin-right: 0;
  min-width: 100px; }

.rmm.style ul li > a:not(:only-child):before {
  position: absolute;
  content: "\f107";
  font-family: fontawesome;
  right: 0;
  display: block;
  top: 15px;
  font-size: 12px; }

.rmm.style ul ul li > a:not(:only-child):after {
  -webkit-transform: rotate(120deg) !important;
  transform: rotate(120deg) !important;
  top: 2px;
  font-size: 14px;
  float: right;
  margin-right: -20px; }

/* mobile style */
.rmm.style .rmm-mobile li > a:not(:only-child):after {
  -webkit-transform: rotate(120deg) !important;
  transform: rotate(120deg) !important;
  top: 4px !important;
  font-size: 14px !important;
  float: right;
  margin-right: -20px; }

.rmm.style .rmm-mobile li {
  background: transparent;
  border-bottom: 1px solid #b5bdc8 !important;
  border-top: 0 !important; }

.rmm.style ul.rmm-mobile li a {
  color: #002E5B; }

/*
 like mobile view style
.rmm.style2 {text-align: left;  font-family: $general_font;}
.rmm.style2 ul {box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15); background:#990; text-transform: uppercase; display: inline-table; text-align:left; width: 100%;}
.rmm.style2 ul li {float: none;}
.rmm.style2 ul li:hover {background: #4b545f;}
.rmm.style2 ul ul {display: none; margin:0; background: #5f6975; border-radius: 0px; padding: 0; position: relative;}
.rmm.style2 ul li a {color: #fff; font-weight:600; display: block; padding: 15px 35px; text-decoration: none; font-weight:600; }
.rmm.style2 ul ul li {border-bottom: 1px solid #575F6A; border-top: 1px solid #6B727C;}
.rmm.style2 ul ul li a {padding: 12px 35px; color: #fff; }
.rmm.style2 ul ul li a:hover {background: #4b545f;}
.rmm.style2 ul ul ul {left: 0;}
*/
.y-gray_bg {
  background: #e2e2e2 none repeat scroll 0 0; }

/* MEDIA CSS */
@media (max-height: 1360px) {
  .y-product_listing_side > div:nth-child(3n+2) {
    margin: auto; } }
@media (max-width: 1200px) {
  .y-our_services .y-servie_info h4 {
    font-size: 16px; }

  .y-our_services .y-servie_info p {
    padding: 0 10px; }

  .y-yacht_intro ul li:first-child {
    margin-right: 3px;
    padding-right: 5px; }

  .y-yacht_intro_img {
    height: 210px; }

  .responsive-tabs-wrapper .y-yacht_intro_img {
    height: auto; }

  .y-back_to_top {
    right: -5px; }

  .y-line {
    right: 20px; }

  .y-blog_detail li::after {
    display: none; }

  .rmm.style ul li a {
    padding: 0.96em 0.9em; }

  .y-home_02 .y-header_form {
    top: 40px; }

  .y-home_02 .y-our_services .y-servie_info span {
    font-size: 12px; }

  .y-pagination {
    margin: auto; } }
@media (max-width: 1023px) {
  .y-landing > .y-inner_header {
    padding-bottom: 250px; }

  .y-footer_strip, .y-popular_items .y-adv_info {
    max-width: 100%; }

  .rmm.style .rmm-mobile ul li a {
    padding: 0.56em 0.86em; }

  .rmm.style .rmm-mobile li ul li {
    border-bottom: none !important; }

  .rmm.style ul.rmm-mobile ul {
    background: transparent; }

  .rmm.style .rmm-mobile ul li a {
    padding: 12px 22px 12px 22px;
    border-bottom: 1px solid #b5bdc8;
    color: #002E5B; }

  .rmm.style .rmm-mobile ul li:last-child a {
    border-bottom: none; }

  .rmm.style ul.rmm-mobile li > a:not(:only-child)::before {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    right: 8px; }

  .y-header_form form.y-form button {
    padding-top: 0; }

  .y-form_gap {
    padding-right: 0; }

  .y-our_services .y-servie_info h4 a {
    padding-left: 5px;
    padding-right: 5px; }

  form.y-form button {
    font-size: 17px;
    padding: 6px 13px 13px; }

  .y-header_outer .y-social {
    padding-top: 10px; }

  .container .y-our_team .y-arrow_line {
    top: -100px;
    left: 0;
    margin: auto;
    right: 0;
    max-width: 260px;
    width: 100%; }

  .bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
    bottom: 10px; }

  .y-yacht_intro_img {
    height: auto; }
    .y-yacht_intro_img img {
      position: static; }

  .y-yacht_intro a {
    font-size: 14px; }

  .y-yacht_intro ul li:first-child::after {
    border: none; }

  .y-breadcrum {
    margin-bottom: 20px; }

  .y-single_info_inner {
    padding-left: 0;
    padding-bottom: 0; }

  .camera_next {
    background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0; }

  .camera_prev, .camera_next, .camera_commands {
    bottom: 0;
    text-align: center; }

  .camera_prev {
    left: 0;
    background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0; }

  .y-relative_item.owl-carousel .owl-item .col-lg-12 {
    max-width: 266px;
    margin: auto;
    float: none; }

  .y-relative_item .y-relative_img {
    padding: 10px;
    max-width: 100%; }

  div.container .y-arrow_line i.y-left_anchor {
    left: 30px; }

  div.container .y-arrow_line i.y-right_anchor {
    right: 30px; }

  .responsive-tabs__list__item {
    padding: 20px 15px;
    font-size: 14px; }

  .responsive-tabs {
    padding-left: 0;
    padding-right: 0; }

  .y-inner_page .y-sidebar {
    margin-right: -15px; }

  .y-single_info_inner .col-sm-8 {
    padding: 0; }

  .y-contact {
    margin-bottom: 25px; }

  .y-single_info_inner .y-contact .col-sm-8 {
    padding: 0 15px; }

  .y-contact address, .y-contact ul li {
    font-size: 14px; }

  .y-header_outer .y-social {
    top: -58px;
    left: 0;
    right: auto; }

  .rmm-toggled {
    top: -62px;
    min-height: 15px; }

  .rmm-toggled-button {
    margin-right: 0; }

  .y-header_01 .rmm.style ul.rmm-mobile {
    margin-top: 0; }

  .y-header_top_info .col-xs-12 {
    padding-bottom: 15px; }

  .y-home_02 .y-header_form, .y-home_02 .y-slider_form > div#y-header_form {
    position: static;
    max-width: 100%; }

  .y-breadcrum {
    margin-bottom: 0; }

  .skrollr-desktop body {
    height: auto !important; } }
@media (min-width: 768px) and (max-width: 1023px) {
  .y-our_services .y-servie_info p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px; }

  .y-our_services .y-servie_info h4 {
    font-size: 16px;
    font-size: 11px; }
    .y-our_services .y-servie_info h4 a {
      padding: 13px 8px; }

  .y-footer .col-lg-3 {
    min-height: 200px; }

  .y-header_strip .y-header_form .y-form > div.col-lg-4 > div.y-form_gap {
    padding-left: 0; }

  .responsive-tabs__list__item {
    padding-left: 10px;
    padding-right: 10px; }

  .y-inner_page .y-sidebar {
    margin-bottom: 30px; }

  .y-menu_outer .y-social {
    display: none; } }
@media (max-width: 767px) {
  .y-menu_outer {
    background: none; }

  .y-menu_outer .y-social {
    display: none; }

  .y-landing > .y-inner_header {
    padding-bottom: 300px;
    background-size: cover; }

  .y-landing .y-landing_list img {
    margin: 10px auto auto; }

  .y-header_strip .y-header_form .y-form > div.col-lg-4 > div {
    padding-right: 0; }

  #skrollr-body {
    height: auto; }

  .header .container > div:nth-child(2) > div {
    padding-bottom: 30px; }

  .y-header_outer .y-social {
    position: absolute;
    top: -75px;
    right: 15px; }

  .y-logo img {
    max-height: 78px; }

  form.y-form button {
    font-size: 17px;
    padding: 6px 13px 8px; }

  .y-header_strip #y-header_form {
    margin-top: 150px; }

  .y-line {
    opacity: 0; }

  .y-back_to_top {
    opacity: 0; }

  .y-dest_list_single .col-sm-7 {
    clear: both;
    padding-top: 1px; }
  .y-dest_list_single img {
    border: none; }

  .y-header_outer address a {
    font-size: 14px; }

  .y-home_02 .y-header_form, .y-home_02 .y-slider_form > div {
    position: static; }

  .y-home_02 .y-header_form {
    padding-left: 0;
    padding-right: 0; }

  .y-lang_icons, .y-login_but {
    text-align: center; }

  .y-single_info_inner {
    padding-left: 0;
    padding-right: 0; }

  .y-breadcrum {
    margin: auto; }
    .y-breadcrum ul {
      float: none !important; }

  .y-product_listing > div {
    border: medium none;
    float: none;
    margin: auto auto 15px;
    max-width: 354px; }

  .y-contact_side {
    float: none !important; }

  .y-pagination {
    margin: auto; }

  .y-product_listing > div {
    max-width: 300px; }

  .y-single_info_inner .col-sm-12 .col-sm-3.pull-right {
    float: none !important; }

  .y-relative_item img {
    position: static; }

  div.container .y-arrow_line {
    display: none; }

  div.container .y-our_team_list .y-arrow_line {
    display: block; }

  .y-product_text_details {
    margin-top: 25px; }

  .y-single_info_inner .y-dest_list h3 {
    margin-top: 20px; }

  .y-single_info_inner li {
    line-height: 22px; }

  .responsive-tabs__heading--active {
    background: #002E5B; }

  .y-inner_page .y-sidebar {
    margin: 0 -15px 20px; }

  .y-breadcrum ul li:first-child a {
    padding-left: 0; }

  .y-blog_general_info li i {
    top: 4px; }

  .y-single_info_inner .y-contact .col-sm-8 {
    padding: 0; }

  .y-contact .y-contact_side {
    clear: both; }

  .y-contact_inner > address:last-child {
    margin-bottom: 0; }

  .y-contact_inner {
    margin-bottom: 15px; }

  .y-header_outer .y-social {
    display: none; }

  .rmm.style {
    padding-top: 0; }

  .y-dest_list_single .pull-right.col-lg-5 img {
    float: none; }

  .y-blog_info {
    margin-top: 15px; }

  .y-fact_single > div {
    height: auto; }

  .y-relative_item {
    padding-top: 30px; }

  .y-relative_outer div.container .y-arrow_line {
    display: block;
    top: 70px; }

  .responsive-tabs .y-client_testimonials {
    padding: 15px 0; }
    .responsive-tabs .y-client_testimonials p {
      text-align: justify; }
    .responsive-tabs .y-client_testimonials img {
      position: static; }

  .y-blog_sidebar {
    float: none !important; }
    .y-blog_sidebar .y-sidebar {
      max-width: 400px;
      margin: auto auto 20px; }

  .y-landing .y-single_info_inner {
    margin: auto; }

  .y-client_says_section {
    background-attachment: scroll; } }

/*# sourceMappingURL=main.css.map */
