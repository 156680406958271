/*========== Mobile First Method  ==========*/
/*==========  Non-Mobile First Method  ==========*/
@media only screen and (max-width: 768px) {
  .header-nav {
    z-index: 101;
  }
}

/*========== Mobile First Method  ==========*/
/*==========  Non-Mobile First Method  ==========*/
@media only screen and (max-width: 768px) {
  .header-nav {
    z-index: 101;
  }
}

.detail-item .inner {
  background-color: #002e5a;
  color: white;
  padding: 80px;
  margin-bottom: 20px;
}

.detail-item i {
  display: block;
  width: 100%;
  font-size: 30pt;
  margin-bottom: 20px;
}

.detail-item strong {
  display: block;
  width: 100%;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.detail-item span {
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
}

.y-contact_inner {
  font-family: sans-serif;
}

.y-contact_inner {
  font-family: sans-serif;
}

/*# sourceMappingURL=customize.css.map */
